@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(135deg, #432AF2 0%, #6425DE 50%, #8A1CC3 100%); */
  background: #000000;
  overflow: hidden;
  position: relative;
}

.content-wrapper {
  text-align: center;
  z-index: 2;
  color: white;
}

.title {
  font-family: 'Lora', serif;
  font-style: bold;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(255, 255, 255, 0.1);
}

.subtitle {
  font-family: 'Lora', serif;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}

.enter-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  background: white;
  color: #5028ea;
  cursor: pointer;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.enter-button:hover {
  background-color: #5028ea;
  color: white;
  box-shadow: 0 0 20px #4a90e2;
  animation: glow 1.5s ease-in-out infinite alternate,
             shine 3s infinite linear;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.0) 45%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.0) 55%
  );
  background-size: 200% 100%;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #4a90e2,
                0 0 10px #4a90e2,
                0 0 15px #4a90e2;
  }
  to {
    box-shadow: 0 0 10px #4a90e2,
                0 0 20px #4a90e2,
                0 0 30px #4a90e2;
  }
}

@keyframes shine {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

.background-animation {
  position: absolute;
  width: 100vw;
  height: 100vw;
  background: radial-gradient(circle, transparent 20%, rgba(80, 40, 234, 0.3) 20%, rgba(128, 31, 202, 0.3) 80%, transparent 80%, transparent),
              radial-gradient(circle, transparent 20%, rgba(80, 40, 234, 0.3) 20%, rgba(128, 31, 202, 0.3) 80%, transparent 80%, transparent) 50px 50px;
  background-size: 100px 100px;
  opacity: 0.1;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
} 